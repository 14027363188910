<template>
  <svg data-name="ArisCorp Logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3072 3072">
    <path
      class="fill-white"
      d="M97.12,2209.09l455.07-907Q729.1,949.46,906,596.86c2.45-4.9,5.15-9.68,9.21-17.29,163,312.55,324.82,623,486.87,933.83,55.12-17.89,109.16-35.4,163.19-53l865.48-281.25L2948.33,1011c3.38-1.1,6.73-2.33,10.09-3.5l3.06,3.82-34.43,14.44-15.12,6.79.09-.08c-2.9.74-5.95,1.12-8.69,2.26q-66.55,27.78-133,55.75-225.39,94.62-450.79,189.25-231.11,96.93-462.26,193.72-243.91,102.29-487.78,204.67-266.61,111.7-533.3,223.18Q489.29,2046.61,142.45,2192C127.56,2198.22,112.24,2203.41,97.12,2209.09ZM905.81,1149.47c-90,215.92-178.82,428.76-267.58,641.6l2.53,2.5L1132.3,1602.4C1056.65,1451.13,982,1301.79,905.81,1149.47Z"
    ></path>
    <path
      class="fill-[#c7c7c7]"
      d="M97.12,2209.09c15.12-5.68,30.44-10.87,45.33-17.1q346.91-145.23,693.72-290.67,266.61-111.67,533.3-223.18,243.93-102.21,487.78-204.67,231.11-96.93,462.26-193.72,225.42-94.56,450.79-189.25,66.49-27.91,133-55.75c2.74-1.14,5.79-1.52,8.69-2.26-2.92,2.51-5.46,5.83-8.83,7.41q-61.2,28.74-122.62,57-83.46,38.6-166.94,77.13-58.61,27.1-117.17,54.35-83.46,38.6-166.95,77.1-62.76,29-125.47,58.15t-125.48,58.15l-165.49,76.61-165.5,76.6q-82.75,38.31-165.51,76.61-79.29,36.68-158.61,73.33-62.76,29-125.48,58.14-63.45,29.36-126.93,58.65c-41.36,19.17-82.66,38.5-124,57.65q-83.44,38.64-167,77.11-59.31,27.4-118.61,54.87-62.75,29.05-125.47,58.15t-125.47,58.15Q427.76,2186,345,2224.28T179.5,2300.86q-73.11,33.66-146.26,66.94c-11.42-8.41-12.1-9.27-7.17-19.25q34.1-69,68.69-137.73C95.15,2210.05,96.32,2209.66,97.12,2209.09Z"
    ></path>
    <path
      class="fill-primary"
      d="M87.7,2454.71,47.22,2398.8c27.62-13,54-25.46,80.5-37.74q55.2-25.59,110.51-50.93Q350.13,2258.58,462,2207c33.61-15.52,67.1-31.32,100.7-46.87q102.84-47.62,205.73-95.13,122.19-56.52,244.34-113.12,109.74-50.83,219.46-101.72,81.42-37.76,162.81-75.57,123.59-57.13,247.23-114.11,78.72-36.36,157.35-72.89,103.56-47.86,207.17-95.63,82.15-38,164.23-76.14,102.84-47.61,205.72-95.14,82.14-38,164.25-76.12,100.8-46.57,201.68-93,80.79-37.3,161.49-74.81,63.49-29.42,127-58.76c.87-.4,2-.24,4.07-.43l15.79,12c-30.21,14.77-58.73,28.78-87.31,42.67-9.12,4.43-18.47,8.37-27.6,12.79q-103.47,50.1-206.91,100.27c-52.55,25.38-105.25,50.46-157.85,75.74q-80.27,38.6-160.47,77.31-79.57,38.31-159.17,76.52l-159.18,76.52-159.17,76.51-159.16,76.53q-80.26,38.59-160.53,77.19-81,38.89-162,77.73-81.65,39.26-163.24,78.62-76.83,37-153.71,73.79-82.32,39.58-164.6,79.29Q892,2068.63,813.69,2106.1q-80.94,38.94-161.83,78-78.89,38-157.81,75.84-83,39.93-166,79.93-79.58,38.3-159.18,76.49C142.39,2429.05,115.76,2441.47,87.7,2454.71Z"
    ></path>
    <path
      class="fill-primary"
      d="M2970.11,956.28c-13.57,5-26.61,10.22-39.88,14.71q-141.39,47.84-282.86,95.47Q2490.92,1119.53,2334.6,1173,2180.36,1225.47,2026,1277.7q-158.61,53.77-317.22,107.56-141.33,48.06-282.58,96.37c-1.85.63-3.8,1-6.52,1.65-14-26.19-27.84-52.06-42.36-79.21,5-2.09,9.31-4.33,13.87-5.7q83.39-24.9,166.85-49.56,124.88-36.78,249.81-73.34,120.52-35.45,241-71,135-39.83,270-79.69l290.35-85.69q135-39.85,270-79.7c21.29-6.28,42.62-12.42,63.92-18.72,12.08-3.57,12-3.63,19.76,6.17,1.26,1.6,2.81,3,4.09,4.57C2967.93,952.52,2968.6,953.85,2970.11,956.28Z"
    ></path>
    <path class="fill-white" d="M1631.82,1985.11,1779,2267.23l-321.8,6.45-58.45-107.61Z"></path>
    <path class="fill-[#c7c7c7]" d="M2961.48,1011.34l-3.06-3.82,30.14-8.68.33.38Z"></path>
    <path
      class="fill-[#c7c7c7]"
      d="M2911.93,1032.57l15.12-6.79C2923.58,1031.55,2918.44,1033.58,2911.93,1032.57Z"
    ></path>
    <path
      class="fill-[#c7c7c7]"
      d="M2988.56,998.84a13.73,13.73,0,0,1,2.88-2.44c.45-.22,1.46.69,3.13,1.56l-5.68,1.26Z"
    ></path>
  </svg>
</template>
